import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoricoAbbonamenti, getPrestazioni, setSnackbarData } from "./programmiSlice";
import SvgProgramma from "../../assets/svg/SvgProgramma";
import { ReactComponent as SvgDownload } from "../../assets/svg/SvgDownload.svg";
import { ReactComponent as SvgGenera } from "../../assets/svg/SvgGenera.svg";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaginateTable from "./components/PaginateTable";
import { formatDate, getAPIErrorMessage } from "../../common/utils";
import { elaboraReferto, eliminaProfessionista, eliminaPrivato, scaricaAvvertenze, scaricaReferto } from "../prestazione/prestazioneAPI";
import { Delete, Launch } from "@mui/icons-material";
import { Footer } from "../homepage/Footer";

export function Referti() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;
  const token = useSelector((state) => state.login.token);
  const userType = useSelector((state) => state.login.user.tipologia);
  const listaPrestazioni = useSelector((state) => state.programmi.listaPrestazioni);
  const pageInfoPrestazioni = useSelector((state) => state.programmi.pageInfoPrestazioni);
  const utente = useSelector((state) => state.login.user);
  const [openEliminaProfessionista, setOpenEliminaProfessionista] = useState(null);
  const [openEliminaPrivato, setOpenEliminaPrivato] = useState(null);

  const [isActionLoading, setIsActionLoading] = useState(false);
  const [screenH, setScreenH] = useState(0);
  const screenRef = React.useRef();

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  useEffect(() => {
    if (userType !== "PP") {
      dispatch(getPrestazioni());
      dispatch(getStoricoAbbonamenti());
    }
  }, []);

  const onGenera = async (value, row) => {
    setIsActionLoading(true);

    try {
      await elaboraReferto(row?.id, {}, token);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error", vertical:'bottom' }));
      return;
    } finally {
      setIsActionLoading(false);
    }

    await onScarica(value, row);

    dispatch(getPrestazioni());
  };

  const onScarica = async (value, row) => {
    let response;
    try {
      response = await scaricaReferto(row.id, token);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error", vertical:'bottom' }));
      return;
    }

    const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Elaborato.pdf");
    document.body.appendChild(link);
    link.click();
  };

  const onEliminaProfessionista = async (value, row) => {
    setOpenEliminaProfessionista(row.id);
  };

  const onConfirmEliminaProfessionista = async () => {
    try {
      await eliminaProfessionista(openEliminaProfessionista, token);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error", vertical:'bottom' }));
      return;
    }

    dispatch(setSnackbarData({ message: "Prestazione eliminata con successo", severity: "success", vertical:'bottom' }));
    setOpenEliminaProfessionista(null);
    dispatch(getPrestazioni());
  };


  const onEliminaPrivato = async (value, row) => {
    setOpenEliminaPrivato(row.id);
  };

  const onConfirmEliminaPrivato = async () => {
    try {
      await eliminaPrivato(openEliminaPrivato, token);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error", vertical:'bottom' }));
      return;
    }

    dispatch(setSnackbarData({ message: "Prestazione eliminata con successo", severity: "success", vertical:'bottom' }));
    setOpenEliminaPrivato(null);
    dispatch(getPrestazioni());
  };

  const onScaricaAvvertenze = async (value, row) => {
    let response;
    try {
      response = await scaricaAvvertenze(row.programma.id, token);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error", vertical:'bottom' }));
      return;
    }

    const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Avvertenze.pdf");
    document.body.appendChild(link);
    link.click();
  };

  const onApri = (value, row) => {
    navigate("/tommy/esecuzione-prestazione", { state: { id_prestazione: row.id } });
  };

  const colonne = [
    { id: "programma", label: "Programma", format: (value) => value.nome },
    { id: "codice_servizio", label: "Codice servizio", format: (value) => value || "-" },
    { id: "data_prestazione", label: "Data Prestazione", format: (value) => formatDate(value) },
  ];

  if (utente.tipologia === "P") {
    colonne.push({ id: "utente_professionista_attivo", sortable: false, label: "Operatore", format: (value) => (value && value.hasOwnProperty("nome") && value.hasOwnProperty("cognome") ? `${value.cognome} ${value.nome}` : "-") });
  }
  if (utente.tipologia === "PA") {
    colonne.push({ id: "dati_paziente", sortable: false, label: "Utente", format: (value) => (value && value.hasOwnProperty("nome") && value.hasOwnProperty("cognome") ? `${value.cognome} ${value.nome}` : "-") });
  }

  colonne.push({
    id: "stato",
    label: "Elaborato",
    sortable: false,
    format: (value, row) => {
      const components = [];
      if (value < 80) {
        components.push(
          <Button onClick={() => onApri(value, row)} variant={"outlined"} color="secondary" startIcon={<Launch />} size="small" style={{ minWidth: 120 }}>
            Programma in corso
          </Button>
        );
      } else {
        if (value === 80) {
          components.push(
            <>
              <Button onClick={() => onGenera(value, row)} variant={"contained"} color="secondary" startIcon={<SvgGenera />} size="small" style={{ minWidth: 120 }}>
                Genera
              </Button>
            </>
          );
        } else {
          components.push(
            <>
              <Button onClick={() => onScarica(value, row)} variant={"outlined"} color="secondary" startIcon={<SvgDownload />} size="small" style={{ minWidth: 120 }}>
                Elaborato
              </Button>
            </>
          );
        }
        if (utente.tipologia == 'PA') {
          components.push(
            <>
              <Button onClick={() => onApri(value, row)} variant={"outlined"} color="secondary" startIcon={<Launch />} size="small" sx={{ minWidth: 120, ml: 2 }}>
                Prestazione
              </Button>
            </>
          );
        }
      }
      if (row.programma?.id_media_avvertenze) {
        components.push(
          <Button onClick={() => onScaricaAvvertenze(value, row)} variant={"outlined"} color="secondary" startIcon={<SvgDownload />} size="small" sx={{ minWidth: 120, ml: 2 }}>
            Avvertenze
          </Button>
        );
      }

      if (utente.tipologia == 'PA' && value >= 80) {
        components.push(
          <>
            <Button onClick={() => onEliminaProfessionista(value, row)} variant={"outlined"} color="error" startIcon={<Delete />} size="small" sx={{ minWidth: 120, ml: 2 }}>
              Elimina
            </Button>
          </>
        );
      }

      if (utente.tipologia == 'P' && value >= 80) {
        components.push(
          <>
            <Button onClick={() => onEliminaPrivato(value, row)} variant={"outlined"} color="error" startIcon={<Delete />} size="small" sx={{ minWidth: 120, ml: 2 }}>
              Elimina
            </Button>
          </>
        );
      }

      return <Box sx={{ display: "flex" }}>
        {components.map((c, i) => <Box key={i} sx={{ display: "inline-block", mr: 1 }}>{c}</Box>)}
      </Box>
    },
  });

  return (
    <Box style={{ display: "flex", height: screenH, flexDirection: "column", overflowY:'auto'  }} ref={screenRef}>
      {isActionLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isActionLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      <Box sx={{ px: 2, py: 2, flex: 1}}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mb: 2 }}>
          <SvgProgramma fill={c.secondary.main} />
          <Typography sx={{ ml: 2, fontSize: '1.5rem' }} variant="title" color="secondary">
            Elaborati
          </Typography>
        </Stack>

        <PaginateTable height='auto' index={2} emptyTable="Non sono presenti elaborati" data={listaPrestazioni} pageInfo={pageInfoPrestazioni} f={getPrestazioni} cols={colonne} />
      </Box>

      <Footer/>

      <Dialog open={openEliminaProfessionista !== null} maxWidth="xs">
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="title2">Elimina la prestazione</Typography>
          <Box sx={{ my: 3 }} />
          <Typography variant="p">Sei sicuro di voler eliminare dall'elenco la prestazione?</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenEliminaProfessionista(null)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button onClick={onConfirmEliminaProfessionista} variant="contained">
            CONFERMA
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEliminaPrivato !== null} maxWidth="xs">
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="title2">Elimina la prestazione</Typography>
          <Box sx={{ my: 3 }} />
          <Typography variant="p">Sei sicuro di voler eliminare dall'elenco la prestazione?</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenEliminaPrivato(null)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button onClick={onConfirmEliminaPrivato} variant="contained">
            CONFERMA
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
